import { Link } from "react-router-dom";

const Footer = () => {

  let pri_color = {
    color:"#06A3DA"
      }
    

  return (
    <footer>
      <div
        className="container-fluid bg-dark text-light mt-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="container">
          <div className="row gx-5">
            <div className="col-lg-4 col-md-6 footer-about">
              <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                <a href="/" className="">
                  <h1 className="">
                    <img className="img" src="/img/dynamicFixLogo.png" />{" "}
                  </h1>
                </a>
                <p className="mt-3 mb-4">
                  Welcome to Dynamic, your trusted partner in cutting-edge
                  software solutions. At Dynamic , we're passionate about
                  harnessing the power of technology to solve complex problems
                  and drive innovation. With a focus on creativity,
                  collaboration, and excellence, we strive to empower businesses
                  and individuals to thrive in the digital age.
                </p>
                <form action="">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control border-white p-3"
                      placeholder="Your Email"
                    />
                    <button className="btn btn-dark">Sign Up</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="row gx-5">
                <div className="col-lg-4 col-md-12 pt-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Get In Touch</h3>
                  </div>
                  <div className="d-flex mb-3">
                    <i className="bi bi-geo-alt text-primary me-2"></i>
                    <p className="mb-0">Road Number 45, Phase 2, Hyderabad, Telangana</p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-envelope-open text-primary me-2"></i>
                    <p className="mb-0">info@dynamicfixtech.com</p>
                  </div>
                  <div className="d-flex mb-3">
                    <i className="bi bi-envelope-open text-primary me-2"></i>
                    <p className="mb-0">sales@dynamicfixtech.com</p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-telephone text-primary me-2"></i>
                    <p className="mb-0">+91 <span className="ms-1">9940</span> 100 234</p>
                  </div>
                  <div className="d-flex mt-4">
                    <a className="btn btn-primary btn-square me-2" href="https://x.com/dynamicfixtech" target="_blank">
                      <i class="fa-brands fa-x-twitter"></i>
                    </a>
                    <a className="btn btn-primary btn-square me-2" href="https://www.facebook.com/dynamicfixtech" target="_blank">
                      <i className="fab fa-facebook-f fw-normal"></i>
                    </a>
                    <a className="btn btn-primary btn-square me-2" href="https://www.linkedin.com/company/dynamicfixtech/" target="_blank">
                      <i className="fab fa-linkedin-in fw-normal"></i>
                    </a>
                    <a className="btn btn-primary btn-square" href="https://instagram.com/dynamicfix.tech" target="_blank">
                      <i className="fab fa-instagram fw-normal"></i>
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Quick Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <Link to="/">
                      <a className="text-light mb-2" href="#">
                        <i className="bi bi-arrow-right text-primary me-2"></i>
                        Home
                      </a>
                    </Link>
                    <Link to="/about">
                      <a className="text-light mb-2" href="#">
                        <i className="bi bi-arrow-right text-primary me-2"></i>
                        About Us
                      </a>
                    </Link>
                    <Link to="/services">
                      <a className="text-light mb-2">
                        <i className="bi bi-arrow-right text-primary me-2"></i>Our
                        Services
                      </a>
                    </Link>
                    <Link    to="/contact">
                      <a className="text-light">
                        <i className="bi bi-arrow-right text-primary me-2"></i>
                        Contact Us
                      </a>
                    </Link>
                  </div>
                </div>
                {/* <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Popular Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <a className="text-light mb-2" href="#">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Home
                    </a>
                    <a className="text-light mb-2" href="#">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      About Us
                    </a>
                    <a className="text-light mb-2" href="#">
                      <i className="bi bi-arrow-right text-primary me-2"></i>Our
                      Services
                    </a>
                    <a className="text-light mb-2" href="#">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Meet The Team
                    </a>
                    <a className="text-light mb-2" href="#">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Latest Blog
                    </a>
                    <a className="text-light" href="#">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Contact Us
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid text-white"
        style={{ background: "#061429" }}
      >
        <div className="container text-center">
          <div className="row justify-content-end">
            <div className="col-lg-8 col-md-6">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "75px" }}
              >
                <p className="mb-0">
        
                  ©  Dynamic Fix Tech.All Rights Reserved. Designed by <a href="https://htmlcodex.com/" target="_blank" className="text-white border-bottom">HTML Codex </a>Distributed By: <a target="_blank" href="https://themewagon.com/" className="border-bottom hola text-primary"> ThemeWagon </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
